import React, { useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { Card } from "react-bootstrap";
import { Button, Grid, Typography, Box, Select, MenuItem } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "../../enums/app-routing";
import CustomTextField from "../FormElements/CommonTextField";
import { AppointmentTypeDto } from "../../models/appointmentTypeDto";
import { AppointmentServiceInternal } from "../../services/appointment.service";
import { Controller } from "react-hook-form";
import { Height } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import { useEffect } from "react"; // Add useEffect for fetching data when editing

export const RequestTypeForm = () => {
  const navigate = useNavigate();
  const { appointmentTypeId } = useParams(); // Get the appointmentTypeId from the URL
  const [selectedTime, setSelectedTime] = useState<number>(0);

  const handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTime(event.target.value as number);
  };

  const validationSchema = yup.object().shape({
    appointmentTypeName: yup
        .string()
        .matches(/^[a-zA-Z0-9 ]*$/, 'Only alphanumeric characters are allowed')
        .required("Request Type is required"),
    appointmentTypeTime: yup.number().required("Request Time is required"),
});


  const methods = useForm<AppointmentTypeDto>({
    defaultValues: new AppointmentTypeDto(),
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    console.log("appointmentTypeId", appointmentTypeId);
    if (appointmentTypeId) {
      // Fetch the request type details for editing and set form data
      AppointmentServiceInternal.appointmenttype().then(response => {
        if (response?.data.isSuccess) {
          const requestType = response.data.data.find((type: any) => type.id === parseInt(appointmentTypeId));
          if (requestType) {
            const { appointment_type, appointment_type_time } = requestType;
            methods.setValue("appointmentTypeName", appointment_type);
            methods.setValue("appointmentTypeTime", appointment_type_time);
            setSelectedTime(appointment_type_time);
          }
        }
      });
    }
  }, [appointmentTypeId, methods]);

  const handleSaveRequestType = async (data: AppointmentTypeDto) => {
    try {
      // If we have an ID, update the existing request type, otherwise create a new one
      if (appointmentTypeId) {
        const response = await AppointmentServiceInternal.saveAppointmentType(
          { ...data, appointmentTypeId: appointmentTypeId ? parseInt(appointmentTypeId) : -1 } // Use -1 for new requests
        );
        if (response.data.isSuccess) {
          toast.success("Request Type updated successfully.");
          navigate(AppRoutings.RequestTypeGrid);
        } else {
          toast.error(response?.data.message);
        }
      } else {
        // Existing save logic for new request
        if (data.appointmentTypeTime !== 0) {
          const response = await AppointmentServiceInternal.saveAppointmentType(data);
          if (response.data.isSuccess) {
            toast.success("Request Type saved successfully.");
            navigate(AppRoutings.RequestTypeGrid);
          } else {
            toast.error(response?.data.message);
          }
        } else {
          toast.error("Request Time is required");
        }
      }
    } catch (error: any) {
      toast.error("Error while saving Request Type, please try again!");
    }
  };

  const generateMinuteOptions = () => {
    const minutes = [];
    for (let i = 0; i < 65; i += 5) {
      if (i !== 0) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  return (
    <>
      <FormProvider {...methods}>
        <Grid className="d-flex justify-content-center">
          <Card style={{ width: "80%" }} className="my-2">
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Typography>Request Type</Typography>
                  <CustomTextField name="appointmentTypeName" fullWidth />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} >
                  <Typography>Request Time</Typography>
                  <Controller
                    name="appointmentTypeTime"
                    defaultValue={selectedTime}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          className="form-control"
                          onChange={(e) => {
                            field.onChange(e);
                            handleTimeChange(e as React.ChangeEvent<{ value: unknown }>);
                          }}
                          sx={{ height: '44%' }}
                        >
                          <MenuItem value="" disabled>
                            Select Minute
                          </MenuItem>
                          {generateMinuteOptions().map(minute => (
                            <MenuItem key={minute} value={minute.toString()}> {minute.toString().padStart(2, '0')}</MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => navigate(AppRoutings.RequestTypeGrid)}
                >
                  Cancel
                </Button>
                <Box sx={{ marginLeft: "10px" }}>
                  <Button
                    variant="contained"
                    onClick={methods.handleSubmit(
                      (formData: AppointmentTypeDto) =>
                        handleSaveRequestType(formData)
                    )}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Card.Body>
          </Card>
        </Grid>
      </FormProvider >
    </>
  );
};
