import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { AppointmentServiceInternal } from "../../services/appointment.service";
import cofirmappointmentlogo from "../../assets/images/ConfmAppointment-logo.png";
import { Button } from '@mui/material';
import FullPageLoader from "../Shared/FullPageLoader";
import { toast } from "react-toastify";


interface ConfirmApplicationProps {
    changeStep: (data: any) => void;
    formData: any;
}

const ConfirmAppointmentForm: React.FC<ConfirmApplicationProps> = (props) => {
    const { formData } = props;
    const [isLoading, setIsLoading] = useState(false);


    const formatDate = (inputDate: string | number | Date) => {
        const dateObject = new Date(inputDate);
        return dateObject.toLocaleDateString('en-US', { weekday: 'long', month: 'numeric', day: 'numeric', year: 'numeric' });
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        event.preventDefault();
        const combinedData = {
            ...formData,
            appointmentdatetime: formData.appointmentDate + "" + formData.appointmentTime
        };
        const postData = {
            appointmentid: 0,
            appointmenttypeid: Number(combinedData.appointmenttypeid),
            providerId: Number(combinedData.providerId),
            appointmentDate: combinedData.appointmentDate.toLocaleString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }),
            //appointmentDate1: new Date(combinedData.appointmentDate),
            appointmentTime: combinedData.appointmentTime,
            inmatename: combinedData.inmatename,
            inmatedob: new Date(combinedData.inmatedob),
            heldforanothercounty: combinedData.heldForAnotherCounty,
            countyid: Number(combinedData.countyid),
            admincountyid: Number(combinedData.admincountyid),
            additionalinfo: combinedData.additionalinfo,
            medications: combinedData.medications,
            providerName: combinedData.providername,
            ZipCode: combinedData.ZipCode
        };

        const response = await AppointmentServiceInternal.saveappointment(postData);
        if (response?.data.isSuccess) {

            window.location.href = "/facility-dashboard";
        }
        else {
            setIsLoading(false);
            toast.error(response?.data.message);
        }
    };

    return (
        <>
            {isLoading && <FullPageLoader />}
            <form onSubmit={onSubmit} className="add-patient-form mt-3">
                <div className="d-flex justify-content-center">
                    <Card style={{ maxWidth: '400px', width: '100%' }}>
                        <Card.Body>
                            <div className="flex gap-3 mb-3 justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="form-title-icon">
                                    <img alt="" src={cofirmappointmentlogo} />
                                </div>
                                <div>
                                    <h2 className="text-xl font-bold mb-1">Confirm Appointment</h2>
                                </div>
                            </div>

                            <div>
                                <div className="form-label mb-1">
                                    <p> {formData.appointmenttypename} <strong>By.</strong> {formData.providername}</p>
                                </div>

                                <div className="form-label mb-3">
                                    <p><strong>On.</strong> {formatDate(formData.appointmentDate)} @ {formData.appointmentTime}</p>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-md-5">
                                        <div className="alignleft control-label form-bold mb-0"><strong>Inmate Name:</strong></div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-0">{formData.inmatename}</div>
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-md-5">
                                        <div className="alignleft control-label form-bold mb-0"><strong>Held For:</strong></div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-0">{formData.heldforaothercountyname}</div>
                                    </div>
                                </div>
                                {formData.medications != "" ? (
                                    <div className="row mb-1">
                                        <div className="col-md-5">
                                            <div className="alignleft control-label form-bold mb-0"><strong>Meditation List:</strong></div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-0">{formData.medications}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mb-1">
                                        <div className="form-label mb-1">
                                            <strong>No medications listed.</strong>
                                        </div>
                                    </div>
                                )}

                                {formData.additionalinfo != "" ? (
                                    <div className="row mb-1">
                                        <div className="col-md-5">
                                            <div className="alignleft control-label form-bold mb-0"><strong>Additional info:</strong></div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-0">{formData.additionalinfo}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row mb-1">
                                        <div className="form-label mb-1">
                                            <strong>No additional information specified.</strong>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mb-1 mt-3 justify-content-center">
                                <div className="col-md-4">
                                    <Button type='button' variant="contained" onClick={() => {
                                        props.changeStep(-1)
                                    }}>Go Back</Button>
                                </div>
                                <div className="col-md-6">
                                    <Button type='submit' variant="contained">Confirm Appointment</Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card >
                </div >
            </form >
        </>
    );
};

export default ConfirmAppointmentForm;
