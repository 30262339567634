import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  OutlinedInput,
  Typography,
  Table, TableCell, TableHead, TableRow, TableBody
} from "@mui/material";
import Layout from "../../components/Layout";
import PersonIcon from "@mui/icons-material/Person";
import UserThreeDots from "../../components/Shared/UserThreeDots";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useEffect, useState } from "react";
import FullPageLoader from "../../components/Shared/FullPageLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppointmentServiceInternal } from "../../services/appointment.service";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from "../../components/Shared/ConfirmationModal";
import NoRecordFoundComponent from "../../components/Shared/NoRecords";
import { BUTTONS, APPOINTMENT_TYPE_ID } from "../../constants";

const RequestTypeManagement = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedReqId, setSelectedReqId] = useState<number>(0);

  const fetchDataFromApi = async () => {
    try {
      setIsLoading(true);
      const response = await AppointmentServiceInternal.appointmenttype();
      if (response?.data.isSuccess) {
        const sortedData = response.data.data.sort((a: any, b: any) => // Sort request type alphabetically
          a.appointment_type.localeCompare(b.appointment_type)
        );
        setData(sortedData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data from API:", error);
    }
  };

  const navigate = useNavigate();
  const handleClick = (e: any) => {
    navigate("/new-request-type");
  };

  const handleDeleteRequestType = async (appointmentTypeId: number) => {
    setSelectedReqId(appointmentTypeId);
    setOpenModel(true);
  };

  const confirmDeleteReqType = async () => {
    const response = await AppointmentServiceInternal.deleteAppointmentType(
      selectedReqId
    );
    if (response?.data.isSuccess) {
      fetchDataFromApi();
      toast.success("Deleted Successfully");
    } else {
      toast.error(response?.data.message);
    }

  }

  const handleEditRequestType = (appointmentTypeId: number) => {
    navigate(`/edit-request-type/${appointmentTypeId}`);
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  return (
    <Layout>
      {isLoading && <FullPageLoader />}
      <Typography
        sx={{
          maxWidth: "100%",
          minHeight: "70%",
          maxHeight: "100%",
          padding: 3,
          margin: 2,
          textAlign: "center",
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '70%' }}>
            <div className="my-3 d-flex align-items-center flex-wrap">
              <Button
                onClick={handleClick}
                sx={{ height: "50px" }}
                variant="contained"
              >
                + New Request Type
              </Button>
              <Table>
                <TableHead>
                  <TableRow style={{ color: "#A2A8BA", backgroundColor: "#F6F7FB" }}>
                    <TableCell>Request Type</TableCell>
                    <TableCell>Request Type Time (in minutes)</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {data && data.length > 0 ? (
                  <TableBody>
                    {data.map((row: any, index: number) => (
                      <TableRow key={index} sx={{ borderRadius: "30px", backgroundColor: "#FFFFFF", borderWidth: "0" }}>
                        <TableCell>
                          {row.appointment_type}
                          {row.id !== APPOINTMENT_TYPE_ID.FIRSTTIMEBEINGSEEN && row.id !== APPOINTMENT_TYPE_ID.FOLLOWUP && ` (${row.appointment_type_time} minutes)`}
                        </TableCell>
                        <TableCell>{row.appointment_type_time}</TableCell>
                          <TableCell>
                                  <>
                                      <Button
                                          variant="contained"
                                          onClick={() => handleEditRequestType(row.id)}
                                          color="primary"
                                          style={{ marginRight: '10px' }}
                                      >
                                          Edit
                                      </Button>
                                      <Button variant="contained" onClick={() => handleDeleteRequestType(row.id)} color="error">
                                          <DeleteIcon />
                                          Delete
                                      </Button>
                                  </>
                          </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <NoRecordFoundComponent cellSpan={3} />
                  </TableBody>
                )}
              </Table>
            </div>
          </div>
        </div>
        <ConfirmationModal
          open={openModel}
          title={"Are you sure you want to delete?"}
          onConfirm={() => {
            setOpenModel(false);
            confirmDeleteReqType();
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          confirmText={BUTTONS.YES}
          cancelText={BUTTONS.NO}
        />
      </Typography>
    </Layout>
  );
};
export default RequestTypeManagement;
